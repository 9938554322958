import React, { useState } from "react";
import { 
  Box, 
  Typography, 
  TextField, 
  Collapse, 
  InputAdornment,
  IconButton,
  Alert,
  Link,
  Tooltip
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

/**
 * AddressInput component that combines GoogleMaps autocomplete with a manual address entry option
 */
const AddressInput = ({ 
  label, 
  value, 
  onChange, 
  type,
  pressedPrediction, 
  GoogleMapsComponent 
}) => {
  const { t } = useTranslation();
  const [manualMode, setManualMode] = useState(false);
  const [manualAddress, setManualAddress] = useState("");
  const [showManualOption, setShowManualOption] = useState(false);
  
  // When the user starts typing in the Google Maps component,
  // we show the manual option button
  const handleInputFocus = () => {
    setShowManualOption(true);
  };
  
  // Switch to manual address entry mode
  const enableManualEntry = () => {
    setManualMode(true);
    // Initialize with the current value if it exists
    if (value && value.description) {
      setManualAddress(value.description);
    }
  };
  
  // Handle the manual address submission
  const handleManualSubmit = () => {
    if (manualAddress.trim()) {
      // Create a mock place result similar to what Google Maps would return
      const mockPlace = {
        description: manualAddress.trim(),
        isManualEntry: true
      };
      // place_id: `manual_${Date.now()}`, // Generate a unique ID
      
      // Call the onChange handler with our mock place
      onChange(mockPlace);
      
      // Exit manual mode
      setManualMode(false);
    }
  };
  
  // Cancel manual entry and go back to Google Maps
  const cancelManualEntry = () => {
    setManualMode(false);
    setManualAddress("");
  };

  return (
    <Box sx={{ mb: 3, position: 'relative' }}>
     <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 1 
        }}>
          <Typography fontWeight="bold">
            {label}
          </Typography>
          
          {/* Manual entry link moved to be next to the label */}
          {showManualOption && (
            <Tooltip title={t("address.enterManually", "Can't find your address? Click to enter manually")}>
            <Link
              component="button"
              variant="caption"
              onClick={enableManualEntry}
              sx={{ 
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontSize: '0.75rem',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              {t("address.enterManuallyShort", "Etkö löydä osoitetta?")}
            </Link>
          </Tooltip>
            )}
        </Box>
      
      {/* Show Google Maps autocomplete when not in manual mode */}
      <Collapse in={!manualMode}>
        <Box onFocus={handleInputFocus}>
          <GoogleMapsComponent
            value={value}
            onChange={onChange}
            type={type}
            pressedPrediction={pressedPrediction}
          />
        </Box>
        
        {/* Show manual entry option after user interacts with the field */}
      </Collapse>
      
      {/* Manual address entry form */}
      <Collapse in={manualMode}>
        <Alert severity="info" sx={{ mb: 2, py: 0.5, fontSize: '0.75rem' }}>
          {t("address.manualEntryInfo", "Enter the address manually if it's not found in Google Maps.")}
        </Alert>
        
        <TextField
          fullWidth
          value={manualAddress}
          onChange={(e) => setManualAddress(e.target.value)}
          placeholder={t("address.manualPlaceholder", "Type complete address")}
          variant="outlined"
          rows={2}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton 
                  onClick={handleManualSubmit}
                  color="primary"
                  title={t("common.apply", "Apply")}
                  size="small"
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  onClick={cancelManualEntry}
                  color="default"
                  title={t("common.cancel", "Cancel")}
                  size="small"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, fontSize: '0.7rem' }}>
          {t("address.manualHint", "Enter a complete address with street, number, postal code and city")}
        </Typography>
      </Collapse>
    
    </Box>
  );
};

export default AddressInput;


