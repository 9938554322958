// components/Footer.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import colors from '../styles/colors'; // Import the colors file


const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: colors.primary, // Use the primary color for the background
        color: colors.textPrimary, // Set the text color
        padding: '16px',
        textAlign: 'center',
      }}
    >
      <Typography variant="body2">
        © 2025 MyXline
      </Typography>

      {/* Social Media Links */}
      <Box sx={{ marginTop: 2 }}>
        <IconButton 
          component="a" 
          href="https://www.instagram.com/myxlinefin" 
          target="_blank" 
          aria-label="Instagram"
          sx={{ color: colors.textPrimary }}
        >
          <InstagramIcon />
        </IconButton>
        <IconButton 
          component="a" 
          href="https://linkedin.com/company/myxlinefinland" 
          target="_blank" 
          aria-label="LinkedIn"
          sx={{ color: colors.textPrimary }}
        >
          <LinkedInIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
