import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { t } from 'i18next';

const PricingPage = () => {
  const pricingData = [
    { distance: "0 - 2 km", weight: "≤ 30 kg", price: "9.5 €", deliveryTime: "0-3 h" },
    { distance: "2 - 5 km", weight: "≤ 30 kg", price: "11.5 €", deliveryTime: "0-3 h" },
    { distance: "5 - 10 km", weight: "≤ 30 kg", price: "16.5 €", deliveryTime: "0-3 h" },
    { distance: "10 - 13 km", weight: "≤ 30 kg", price: "19.5 €", deliveryTime: "0-3 h" },
    { distance: "13 - 18 km", weight: "≤ 30 kg", price: "31.68 €", deliveryTime: "0-3 h" },
    { distance: "18 - 22 km", weight: "≤ 30 kg", price: "38.28 €", deliveryTime: "0-4 h" },
    { distance: "22 - 30 km", weight: "≤ 30 kg", price: "50.16 €", deliveryTime: "0-4 h" },
    { distance: "30 - 40 km", weight: "≤ 30 kg", price: "58.08 €", deliveryTime: "0-4 h" },
    { distance: "40 - 50 km", weight: "≤ 30 kg", price: "75.24 €", deliveryTime: "0-4 h" },
    { distance: "50+ km", weight: "≤ 30 kg", price: "57 € + 0.66 €/km", deliveryTime: "-" },
  ];

  const additionalServices = [
    { service: t('pricingPage.invoiceFee'), price: "6 €" },
    { service: t('pricingPage.floorSurcharge'), price: "6 €" },
    { service: t('pricingPage.rushDelivery'), price: "2x normal price" },
    { service: t('pricingPage.temperatureBox12L'), price: "95 €" },
    { service: t('pricingPage.temperatureBox45L'), price: "125 €" },
    { service: t('pricingPage.gelPacks'), price: "20 €" },
    { service: t('pricingPage.largeVehicle'), price: "Sop. mukaan" },
    { service: t('pricingPage.packageCarRate'), price: "56 €/h" },
  ];

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h4" fontWeight="bold" mb={4} gutterBottom>
        {t('pricingPage.pricingTitle')}
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell>{t('pricingPage.distance')}</TableCell>
              <TableCell>{t('pricingPage.weightLimit')}</TableCell>
              <TableCell>{t('pricingPage.price')} + {t('tax')}</TableCell>
              <TableCell>{t('pricingPage.deliveryTime')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricingData.map((row, index) => (
              <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                <TableCell>{row.distance}</TableCell>
                <TableCell>{row.weight}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.deliveryTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{marginBottom:20}}>
      <Typography variant="body1" mt={4} fontStyle="italic">
        {t('pricingPage.loyaltyDiscounts')}
      </Typography>
      </div>
      <Typography variant="h5" fontWeight="bold" mb={2} gutterBottom>
        {t('pricingPage.additionalServices')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {additionalServices.map((service, index) => (
              <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                <TableCell>{service.service}</TableCell>
                <TableCell>{service.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PricingPage;
