import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Paper,
  Grid,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  InputAdornment,
  Alert,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@mui/icons-material/Business";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import moment from "moment";

const PaymentStep = ({ delivery, dispatch, isMobile, changeActiveDeliveryValues }) => {
  const { t } = useTranslation();
  const [showCompanyFields, setShowCompanyFields] = useState(delivery?.paymentMethod === "bill");

  const handlePaymentMethodChange = (event) => {
    const value = event.target.value;
    dispatch(
      changeActiveDeliveryValues({
        fieldValue: "paymentMethod",
        val: value,
      })
    );
    setShowCompanyFields(value === "bill");
  };

  const handleInputChange = (field, value) => {
    dispatch(
      changeActiveDeliveryValues({
        fieldValue: field,
        val: value,
      })
    );
  };

  // Calculate price details based on delivery configuration
  const calculatePriceDetails = () => {
    const hasPickup = !!delivery?.fromSelfDeliverAdress;
    const hasDelivery = !!delivery?.toSelfDeliverAdress;
    const hasBusChange = !!delivery?.bussChangeAdress?.name;
    
    const priceItems = [];
    let totalPrice = 0;
    
    // Add bus transport fee
    if (delivery?.from && delivery?.to) {
      priceItems.push({
        description: t("payment.singleBusTransport", "Bus Transport"),
        price: 17.90
      });
      totalPrice += 17.90;
    }
    
    // Add bus change fee if applicable
    if (hasBusChange) {
      priceItems.push({
        description: t("payment.busChange", "Bus Change Fee"),
        price: 24.90
      });
      totalPrice += 24.90;
    }
    
    // Add pickup fee if applicable
    if (hasPickup) {
      const pickupDistanceKm = delivery?.pickupDistance ? 
        delivery.pickupDistance / 1000 : 0;
      
      let pickupPrice = 15.00; // Base fee for up to 10 km
      let pickupDescription = t("payment.pickupService", "Pickup Service (up to 10 km)");
      
      // Add extra distance fee if applicable
      if (pickupDistanceKm > 10) {
        const extraKm = Math.ceil(pickupDistanceKm - 10);
        const extraFee = extraKm * 0.95;
        pickupPrice += extraFee;
        pickupDescription = t("payment.pickupServiceWithDistance", 
          "Pickup Service ({{distance}} km, including {{extraKm}} km extra)", 
          { distance: Math.round(pickupDistanceKm * 10) / 10, extraKm: extraKm }
        );
      }
      
      priceItems.push({
        description: pickupDescription,
        price: pickupPrice
      });
      totalPrice += pickupPrice;
    }
    
    // Add delivery fee if applicable
    if (hasDelivery) {
      const deliveryDistanceKm = delivery?.deliveryDistance ? 
        delivery.deliveryDistance / 1000 : 0;
      
      let deliveryPrice = 15.00; // Base fee for up to 10 km
      let deliveryDescription = t("payment.deliveryService", "Delivery Service (up to 10 km)");
      
      // Add extra distance fee if applicable
      if (deliveryDistanceKm > 10) {
        const extraKm = Math.ceil(deliveryDistanceKm - 10);
        const extraFee = extraKm * 0.95;
        deliveryPrice += extraFee;
        deliveryDescription = t("payment.deliveryServiceWithDistance", 
          "Delivery Service ({{distance}} km, including {{extraKm}} km extra)", 
          { distance: Math.round(deliveryDistanceKm * 10) / 10, extraKm: extraKm }
        );
      }
      
      priceItems.push({
        description: deliveryDescription,
        price: deliveryPrice
      });
      totalPrice += deliveryPrice;
    }
    
    // Calculate VAT
    const vat = totalPrice * 0.255;
    const totalWithVat = totalPrice + vat;
    
    return {
      items: priceItems,
      basePrice: totalPrice.toFixed(2),
      vat: vat.toFixed(2),
      total: totalWithVat.toFixed(2)
    };
  };

  const priceDetails = calculatePriceDetails();

  return (
    <Box>
      {/* Order Summary Section */}
      <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t("payment.orderSummary", "Order Summary")}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">
              {t("payment.sender", "Sender")}:
            </Typography>
            <Typography variant="body2" gutterBottom>
              {delivery?.senderName || "-"}
            </Typography>
            
            <Typography variant="subtitle2">
              {t("payment.pickupLocation", "Pickup")}:
            </Typography>
            <Typography variant="body2" gutterBottom>
              {delivery?.fromSelfDeliverAdress || delivery?.from || "-"}
            </Typography>
            
            <Typography variant="subtitle2">
              {t("payment.departureTime", "Departure Time")}:
            </Typography>
            <Typography variant="body2" gutterBottom>
              {delivery?.departureTime ? moment(delivery.departureTime).format("DD.MM.YYYY HH:mm") : "-"}
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">
              {t("payment.receiver", "Receiver")}:
            </Typography>
            <Typography variant="body2" gutterBottom>
              {delivery?.receiverName || "-"}
            </Typography>
            
            <Typography variant="subtitle2">
              {t("payment.deliveryLocation", "Delivery")}:
            </Typography>
            <Typography variant="body2" gutterBottom>
              {delivery?.toSelfDeliverAdress || delivery?.to || "-"}
            </Typography>
            
            <Typography variant="subtitle2">
              {t("payment.arrivalTime", "Arrival Time")}:
            </Typography>
            <Typography variant="body2" gutterBottom>
              {delivery?.arrivalTime ? moment(delivery.arrivalTime).format("DD.MM.YYYY HH:mm") : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Price Details */}
      <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t("payment.priceDetails", "Price Details")}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("payment.service", "Service")}</TableCell>
                <TableCell align="right">{t("payment.price", "Price")} (€)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceDetails.items.map((item, index) => (
                <TableRow key={`price-item-${index}`}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell align="right">{item.price.toFixed(2)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>{t("payment.vat", "VAT (25.5%)")}</TableCell>
                <TableCell align="right">{priceDetails.vat}</TableCell>
              </TableRow>
              <TableRow sx={{ '& td': { fontWeight: 'bold', borderTop: '2px solid rgba(2255, 2255, 2255, 1)' } }}>
                <TableCell>{t("payment.total", "Total")}</TableCell>
                <TableCell align="right">{priceDetails.total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Payment Method Selection */}
      <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t("payment.paymentMethod", "Payment Method")}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment-method"
            name="payment-method"
            value={delivery?.paymentMethod || "card"}
            onChange={handlePaymentMethodChange}
          >
            <FormControlLabel
              value="card"
              control={<Radio />}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CreditCardIcon sx={{ mr: 1 }} />
                  {t("payment.card", "Credit Card")}
                </Box>
              }
            />
            <FormControlLabel
              value="bill"
              control={<Radio />}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ReceiptIcon sx={{ mr: 1 }} />
                  {t("payment.bill", "Bill (Invoice)")}
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      {/* Company Information (shows when bill is selected) */}
      <Collapse in={showCompanyFields}>
        <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t("payment.companyInfo", "Company Information")}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          
          <TextField
            fullWidth
            label={t("payment.companyName", "Company Name")}
            variant="outlined"
            margin="normal"
            value={delivery?.companyName || ""}
            onChange={(e) => handleInputChange("companyName", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          
          <TextField
            fullWidth
            label={t("payment.businessId", "Business ID (Y-tunnus)")}
            variant="outlined"
            margin="normal"
            value={delivery?.businessId || ""}
            onChange={(e) => handleInputChange("businessId", e.target.value)}
            placeholder="1234567-8"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          
          <TextField
            fullWidth
            label={t("payment.referenceNumber", "Reference Number (Optional)")}
            variant="outlined"
            margin="normal"
            value={delivery?.referenceNumber || ""}
            onChange={(e) => handleInputChange("referenceNumber", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalOfferIcon />
                </InputAdornment>
              ),
            }}
          />
          
          <Alert severity="info" sx={{ mt: 2 }}>
            {t("payment.invoiceInfo", "An invoice will be sent to the provided email address. Payment terms: 14 days net.")}
          </Alert>
        </Paper>
      </Collapse>
    </Box>
  );
};

export default PaymentStep;