import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Tooltip,
  Link,
  Collapse,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
// Import these at the top with your other imports

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckIcon from "@mui/icons-material/Check";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import GoogleMaps from "../MapsAutoComplete";
import { changeActiveDeliveryValues } from "../../redux/deliverySlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddressInput from "./AddressInput";

const BusCompanySelector = ({ value, onChange, className }) => {
  const { t } = useTranslation();
  const [otherCompanyName, setOtherCompanyName] = useState("");
  const isOtherSelected = value === "OTHER";

  const busCompanies = [
    { value: "OB", label: t("busCompanies.onniBus", "OnniBus") },
    {
      value: "KOSONEN",
      label: t("busCompanies.matkaKosonen", "Matka-Kosonen"),
    },
    {
      value: "SOISALO",
      label: t("busCompanies.soisalonLiikenne", "Soisalon liikenne"),
    },
    { value: "SAVONLINJA", label: t("busCompanies.savonlinja", "Savonlinja") },
    {
      value: "POHJOLAN",
      label: t("busCompanies.pohjolanLiikenne", "Pohjolan Liikenne"),
    },
    {
      value: "KOIVISTO",
      label: t("busCompanies.koivistonAuto", "Koiviston Auto"),
    },
    { value: "OTHER", label: t("busCompanies.other", "Muu bussiyhtiö") },
  ];

  // Handle change in the other company name field
  const handleOtherCompanyChange = (e) => {
    const name = e.target.value;
    setOtherCompanyName(name);

    // Pass both the "OTHER" value and the custom name to the parent component
    onChange("OTHER", name);
  };

  return (
    <Box className={className} sx={{ mb: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography fontWeight="bold">
          {t("delivery.busCompany", "Bussiyhtiö")}
        </Typography>
        <Link
          href="https://liput.matkahuolto.fi/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "0.75rem",
            gap: 0.5,
          }}
        >
          {t("delivery.findBusCompany", "Etsi sopiva bussiyhtiö")}
        </Link>
      </Box>

      <FormControl fullWidth>
        <InputLabel id="bus-company-label">
          {t("delivery.selectBusCompany", "Valitse bussiyhtiö")}
        </InputLabel>
        <Select
          labelId="bus-company-label"
          id="bus-company-select"
          value={value || "OB"}
          onChange={(e) => {
            // Regular change handler for non-OTHER options
            if (e.target.value !== "OTHER") {
              setOtherCompanyName(""); // Clear other company name when switching
            }
            onChange(e.target.value);
          }}
          label={t("delivery.selectBusCompany", "Valitse bussiyhtiö")}
        >
          {busCompanies.map((company) => (
            <MenuItem key={company.value} value={company.value}>
              {company.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Show text field for custom bus company name when "OTHER" is selected */}
      <Collapse in={isOtherSelected} timeout={300}>
        <TextField
          fullWidth
          margin="normal"
          label={t("delivery.specifyBusCompany", "Määritä bussiyhtiö")}
          value={otherCompanyName}
          onChange={handleOtherCompanyChange}
          placeholder={t(
            "delivery.enterBusCompanyName",
            "Syötä bussiyhtiön nimi"
          )}
          size="small"
          sx={{ mt: 1 }}
        />
      </Collapse>
    </Box>
  );
};

const DeliveryPathSelector = ({
  delivery,
  onSubmit,
  pressedPrediction,
  handleOnChangeTime,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [deliveryPath, setDeliveryPath] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("direct");
  // Add this state variable to your component
  const [showMoreTemplates, setShowMoreTemplates] = useState(false);
  const busCompanies = [
    { value: "OB", label: t("busCompanies.onniBus", "OnniBus") },
    {
      value: "KOSONEN",
      label: t("busCompanies.matkaKosonen", "Matka-Kosonen"),
    },
    {
      value: "SOISALO",
      label: t("busCompanies.soisalonLiikenne", "Soisalon liikenne"),
    },
    { value: "SAVONLINJA", label: t("busCompanies.savonlinja", "Savonlinja") },
    {
      value: "POHJOLAN",
      label: t("busCompanies.pohjolanLiikenne", "Pohjolan Liikenne"),
    },
    {
      value: "KOIVISTO",
      label: t("busCompanies.koivistonAuto", "Koiviston Auto"),
    },
    { value: "OTHER", label: t("busCompanies.other", "Muu bussiyhtiö") },
  ];

  // Your full templates array would look like this:
  const templates = [
    {
      id: "direct",
      name: t("deliveryTemplates.direct.name", "Suora bussikuljetus"),
      description: t(
        "deliveryTemplates.direct.desc",
        "Lähtöpysäkiltä saapumispysäkille"
      ),
      path: [{ type: "bus", name: t("deliverySegments.bus", "Bussikuljetus") }],
    },
    {
      id: "pickup-bus-delivery",
      name: t(
        "deliveryTemplates.pickupBusDelivery.name",
        "Nouto, bussikuljetus ja toimitus"
      ),
      description: t(
        "deliveryTemplates.pickupBusDelivery.desc",
        "MyXline noutaa ja toimittaa lähtöpysäkille, bussikuljetus saapumispysäkille, josta MyXline toimittaa perille"
      ),
      path: [
        { type: "pickup", name: t("deliverySegments.pickup", "MyXline nouto") },
        { type: "bus", name: t("deliverySegments.bus", "Bussikuljetus") },
        {
          type: "delivery",
          name: t("deliverySegments.delivery", "MyXline toimitus"),
        },
      ],
    },
    {
      id: "bus-change",
      name: t("deliveryTemplates.busChange.name", "Bussikuljetus vaihdolla"),
      description: t(
        "deliveryTemplates.busChange.desc",
        "Lähtöpysäkiltä vaihtoaseman kautta saapumispysäkille"
      ),
      path: [
        { type: "bus", name: t("deliverySegments.bus1", "Bussikuljetus 1") },
        { type: "change", name: t("deliverySegments.change", "Vaihto") },
        { type: "bus", name: t("deliverySegments.bus2", "Bussikuljetus 2") },
      ],
    },
    {
      id: "full-service",
      name: t("deliveryTemplates.fullService.name", "Täysi palvelu"),
      description: t(
        "deliveryTemplates.fullService.desc",
        "MyXline noutaa, toimittaa lähtöpysäkille, bussikuljetus vaihdolla, saapumispysäkiltä MyXline toimittaa perille"
      ),
      path: [
        { type: "pickup", name: t("deliverySegments.pickup", "MyXline nouto") },
        { type: "bus", name: t("deliverySegments.bus1", "Bussikuljetus 1") },
        { type: "change", name: t("deliverySegments.change", "Vaihto") },
        { type: "bus", name: t("deliverySegments.bus2", "Bussikuljetus 2") },
        {
          type: "delivery",
          name: t("deliverySegments.delivery", "MyXline toimitus"),
        },
      ],
    },
    {
      id: "pickup-bus",
      name: t("deliveryTemplates.pickupBus.name", "Nouto ja bussikuljetus"),
      description: t(
        "deliveryTemplates.pickupBus.desc",
        "MyXline noutaa ja toimittaa lähtöpysäkille, josta bussikuljetus saapumispysäkille"
      ),
      path: [
        { type: "pickup", name: t("deliverySegments.pickup", "MyXline nouto") },
        { type: "bus", name: t("deliverySegments.bus", "Bussikuljetus") },
      ],
    },
    {
      id: "bus-delivery",
      name: t(
        "deliveryTemplates.busDelivery.name",
        "Bussikuljetus ja toimitus"
      ),
      description: t(
        "deliveryTemplates.busDelivery.desc",
        "Lähtöpysäkiltä bussikuljetus saapumispysäkille, josta MyXline toimittaa perille"
      ),
      path: [
        { type: "bus", name: t("deliverySegments.bus", "Bussikuljetus") },
        {
          type: "delivery",
          name: t("deliverySegments.delivery", "MyXline toimitus"),
        },
      ],
    },


    {
      id: "custom",
      name: t("deliveryTemplates.custom.name", "Mukautettu"),
      description: t(
        "deliveryTemplates.custom.desc",
        "Valitse haluamasi kuljetusyhdistelmä"
      ),
      path: [],
    },
  ];

  // Initialize delivery locations based on selected template
  useEffect(() => {
    const template = templates.find((t) => t.id === selectedTemplate);
    if (template && template.id !== "custom") {
      setDeliveryPath(template.path);
    }
  }, [selectedTemplate]);

  // Add a delivery segment to the custom path
  const addPathSegment = (type) => {
    let newSegment;
    switch (type) {
      case "pickup":
        newSegment = {
          type: "pickup",
          name: t("deliverySegments.pickup", "MyXline nouto"),
        };
        break;
      case "bus":
        newSegment = {
          type: "bus",
          name: t("deliverySegments.busNumbered", "Bussikuljetus {{number}}", {
            number: deliveryPath.filter((p) => p.type === "bus").length + 1,
          }),
        };
        break;
      case "change":
        newSegment = {
          type: "change",
          name: t("deliverySegments.change", "Vaihto"),
        };
        break;
      case "delivery":
        newSegment = {
          type: "delivery",
          name: t("deliverySegments.delivery", "MyXline toimitus"),
        };
        break;
      default:
        newSegment = {
          type: "bus",
          name: t("deliverySegments.bus", "Bussikuljetus"),
        };
    }

    setDeliveryPath([...deliveryPath, newSegment]);
  };

  // Remove a delivery segment from the custom path
  const removePathSegment = (index) => {
    const newPath = [...deliveryPath];
    newPath.splice(index, 1);
    setDeliveryPath(newPath);
  };

  // Handle form back and next navigation
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    setActiveStep(0);
  };

  // Render delivery path icons
  // Modify your renderPathIcon function to include colors
  const renderPathIcon = (type) => {
    switch (type) {
      case "pickup":
        return (
          <LocalShippingIcon
            sx={{ transform: "scaleX(-1)", color: "#4caf50" }}
          />
        ); // Green
      case "bus":
        return <DirectionsBusIcon sx={{ color: "#2196f3" }} />; // Blue
      case "change":
        return <SwapHorizIcon sx={{ color: "#ff9800" }} />; // Orange
      case "delivery":
        return <LocalShippingIcon sx={{ color: "#9c27b0" }} />; // Purple
      default:
        return null;
    }
  };

  // Visual representation of the delivery path
  const renderPathVisualizer = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          overflowX: "auto",
          mb: 3,
          p: 2,
          backgroundColor: "background.default",
          borderRadius: 1,
        }}
      >
        {deliveryPath.map((segment, index) => (
          <React.Fragment key={`path-${index}`}>
            {index > 0 && <Box sx={{ mx: 1 }}>→</Box>}
            <Box
              sx={{
                p: 1,
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "100px",
                textAlign: "center",
                bgcolor:
                  activeStep === index + 1 ? "primary.light" : "transparent",
                color:
                  activeStep === index + 1
                    ? "primary.contrastText"
                    : "text.primary",
              }}
            >
              {renderPathIcon(segment.type)}
              <Typography variant="caption">{segment.name}</Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    );
  };

  // Generate form steps based on delivery path
  const getSteps = () => {
    const steps = [
      {
        label: t("deliveryPath.selectMethod", "Valitse kuljetustapa"),
        content: (
          <Box
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              pr: 1,
              mb: 2,
              // Add subtle scrollbar styling
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Box style={{margin: 10}}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                {templates.slice(0, 3).map((template) => (
                  <Grid item xs={12} sm={6} md={4} key={template.id}>
                    <Card
                      onClick={() => setSelectedTemplate(template.id)}
                      sx={{
                        cursor: "pointer",
                        height: "100%",
                        boxShadow: selectedTemplate === template.id ? 8 : 1,
                        borderColor:
                          selectedTemplate === template.id
                            ? "primary.main"
                            : "transparent",
                        borderWidth: 2,
                        borderStyle: "solid",
                        position: "relative",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          boxShadow: 4,
                          transform: "translateY(-2px)",
                        },
                      }}
                    >
                      {selectedTemplate === template.id && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            bgcolor: "primary.main",
                            color: "white",
                            borderRadius: "50%",
                            width: 24,
                            height: 24,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CheckIcon fontSize="small" />
                        </Box>
                      )}

                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          fontWeight="medium"
                          gutterBottom
                        >
                          {template.name}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            mb: 2,
                            overflowX: "auto",
                            pb: 1,
                          }}
                        >
                          {template.path.map((segment, index) => (
                            <React.Fragment key={`path-segment-${index}`}>
                              {index > 0 && (
                                <Box sx={{ mx: 0.5, color: "text.secondary" }}>
                                  →
                                </Box>
                              )}
                              <Tooltip title={segment.name} arrow>
                                <Box
                                  sx={{
                                    p: 0.75,
                                    bgcolor: "action.hover",
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {renderPathIcon(segment.type)}
                                </Box>
                              </Tooltip>
                            </React.Fragment>
                          ))}
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                          {template.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/* Show more options section */}
              <Collapse in={showMoreTemplates}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {templates.slice(3, templates.length - 1).map((template) => (
                    <Grid item xs={12} sm={6} md={4} key={template.id}>
                      <Card
                        onClick={() => setSelectedTemplate(template.id)}
                        sx={{
                          cursor: "pointer",
                          height: "100%",
                          boxShadow: selectedTemplate === template.id ? 8 : 1,
                          borderColor:
                            selectedTemplate === template.id
                              ? "primary.main"
                              : "transparent",
                          borderWidth: 2,
                          borderStyle: "solid",
                          position: "relative",
                          transition: "all 0.2s ease-in-out",
                          "&:hover": {
                            boxShadow: 4,
                            transform: "translateY(-2px)",
                          },
                        }}
                      >
                        {selectedTemplate === template.id && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              bgcolor: "primary.main",
                              color: "white",
                              borderRadius: "50%",
                              width: 24,
                              height: 24,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </Box>
                        )}

                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            fontWeight="medium"
                            gutterBottom
                          >
                            {template.name}
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "nowrap",
                              mb: 2,
                              overflowX: "auto",
                              pb: 1,
                            }}
                          >
                            {template.path.map((segment, index) => (
                              <React.Fragment key={`path-segment-${index}`}>
                                {index > 0 && (
                                  <Box
                                    sx={{ mx: 0.5, color: "text.secondary" }}
                                  >
                                    →
                                  </Box>
                                )}
                                <Tooltip title={segment.name} arrow>
                                  <Box
                                    sx={{
                                      p: 0.75,
                                      bgcolor: "action.hover",
                                      borderRadius: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {renderPathIcon(segment.type)}
                                  </Box>
                                </Tooltip>
                              </React.Fragment>
                            ))}
                          </Box>

                          <Typography variant="body2" color="text.secondary">
                            {template.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Collapse>

              {/* Custom template card - always visible */}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    onClick={() => setSelectedTemplate("custom")}
                    sx={{
                      cursor: "pointer",
                      height: "100%",
                      boxShadow: selectedTemplate === "custom" ? 8 : 1,
                      borderColor:
                        selectedTemplate === "custom"
                          ? "primary.main"
                          : "transparent",
                      borderWidth: 2,
                      borderStyle: "solid",
                      position: "relative",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        boxShadow: 4,
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    {selectedTemplate === "custom" && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          bgcolor: "primary.main",
                          color: "white",
                          borderRadius: "50%",
                          width: 24,
                          height: 24,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CheckIcon fontSize="small" />
                      </Box>
                    )}

                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        fontWeight="medium"
                        gutterBottom
                      >
                        {t("deliveryTemplates.custom.name", "Mukautettu")}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        {t(
                          "deliveryTemplates.custom.desc",
                          "Valitse haluamasi kuljetusyhdistelmä"
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* Show/hide additional templates */}
              <Button
                variant="text"
                onClick={() => setShowMoreTemplates(!showMoreTemplates)}
                startIcon={
                  showMoreTemplates ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                sx={{ mb: 2 }}
              >
                {showMoreTemplates
                  ? t("common.showLess", "Näytä vähemmän")
                  : t("common.showMore", "Näytä lisää vaihtoehtoja")}
              </Button>

              {/* Custom template section if needed */}
              {selectedTemplate === "custom" && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    border: 1,
                    borderColor: "divider",
                  }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {t(
                      "deliveryPath.buildCustomRoute",
                      "Rakenna omaa kuljetusreittiä:"
                    )}
                  </Typography>

                  <Box display="flex" flexWrap="wrap" mt={2} gap={1}>
                    <Button
                      variant="outlined"
                      startIcon={
                        <LocalShippingIcon sx={{ transform: "scaleX(-1)" }} />
                      }
                      onClick={() => addPathSegment("pickup")}
                      disabled={deliveryPath.some((p) => p.type === "pickup")}
                    >
                      {t("deliveryPath.addPickup", "Lisää nouto")}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DirectionsBusIcon />}
                      onClick={() => addPathSegment("bus")}
                    >
                      {t("deliveryPath.addBus", "Lisää bussikuljetus")}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<SwapHorizIcon />}
                      onClick={() => addPathSegment("change")}
                      disabled={
                        deliveryPath.filter((p) => p.type === "bus").length < 1
                      }
                    >
                      {t("deliveryPath.addChange", "Lisää vaihto")}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<LocalShippingIcon />}
                      onClick={() => addPathSegment("delivery")}
                      disabled={deliveryPath.some((p) => p.type === "delivery")}
                    >
                      {t("deliveryPath.addDelivery", "Lisää toimitus")}
                    </Button>
                  </Box>

                  {deliveryPath.length > 0 && (
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("deliveryPath.currentRoute", "Nykyinen reitti:")}
                      </Typography>
                      <Box>
                        {deliveryPath.map((segment, index) => (
                          <Box
                            key={`segment-${index}`}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              p: 1,
                              border: 1,
                              borderColor: "divider",
                              borderRadius: 1,
                              mb: 2,
                            }}
                          >
                            {renderPathIcon(segment.type)}
                            <Typography sx={{ ml: 1, flex: 1 }}>
                              {segment.name}
                            </Typography>
                            <Button
                              size="small"
                              color="error"
                              onClick={() => removePathSegment(index)}
                            >
                              {t("common.remove", "Poista")}
                            </Button>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ),
      },
    ];

    // Add steps for each path segment
    deliveryPath.forEach((segment, index) => {
      let stepContent;

      switch (segment.type) {
        case "pickup":
          stepContent = (
            <Box>
              <AddressInput
                label={t("delivery.pickupAddress", "Nouto-osoite")}
                value={
                  delivery?.fromSelfDeliverAdress
                    ? { description: delivery.fromSelfDeliverAdress }
                    : null
                }
                onChange={(place) =>
                  pressedPrediction("fromSelfDeliverAdress", place)
                }
                type="fromSelfDeliverAdress"
                pressedPrediction={pressedPrediction}
                GoogleMapsComponent={GoogleMaps}
              />
            </Box>
          );
          break;

        case "bus":
          // First bus segment has departure, others have connection info
          const bussCompany = delivery?.bussCompany || "";

          if (index === deliveryPath.findIndex((p) => p.type === "bus")) {
            stepContent = (
              <Box>
                <AddressInput
                  label={t("delivery.departureStop", "Lähtöpysäkki")}
                  value={delivery?.from ? { description: delivery.from } : null}
                  onChange={(place) => pressedPrediction("from", place)}
                  type="from"
                  pressedPrediction={pressedPrediction}
                  GoogleMapsComponent={GoogleMaps}
                />

                <BusCompanySelector
                  value={bussCompany}
                  onChange={(value) => {
                    dispatch(
                      changeActiveDeliveryValues({
                        fieldValue: "bussCompany",
                        val: value,
                      })
                    );
                  }}
                />

                <Box sx={{ mb: 3 }}>
                  <Typography fontWeight="bold" mb={1}>
                    {t("delivery.departureTime", "Lähtöaika")}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      value={
                        delivery?.departureTime
                          ? moment(delivery.departureTime)
                          : moment()
                      }
                      onChange={(date) =>
                        handleOnChangeTime("departureTime", date?.toDate())
                      }
                      slotProps={{
                        textField: { fullWidth: true },
                      }}
                      format="DD.MM.YYYY HH:mm"
                      ampm={false}
                    />
                  </LocalizationProvider>
                </Box>

                <AddressInput
                  label={t("delivery.arrivalStop", "Saapumispysäkki")}
                  value={delivery?.to ? { description: delivery.to } : null}
                  onChange={(place) => pressedPrediction("to", place)}
                  type="to"
                  pressedPrediction={pressedPrediction}
                  GoogleMapsComponent={GoogleMaps}
                />

                <Box sx={{ mb: 3 }}>
                  <Typography fontWeight="bold" mb={1}>
                    {t("delivery.arrivalTime", "Saapumisaika")}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      value={
                        delivery?.arrivalTime
                          ? moment(delivery.arrivalTime)
                          : moment().add(1, "hour")
                      }
                      onChange={(date) =>
                        handleOnChangeTime("arrivalTime", date?.toDate())
                      }
                      slotProps={{
                        textField: { fullWidth: true },
                      }}
                      format="DD.MM.YYYY HH:mm"
                      ampm={false}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            );
          } else {
            const bussCompany2 = delivery?.bussCompany2 || "";

            // Last bus segment with destination
            if (index === deliveryPath.map((p) => p.type).lastIndexOf("bus")) {
              stepContent = (
                <Box>
                  <AddressInput
                    label={t("delivery.arrivalStop", "Saapumispysäkki")}
                    value={delivery?.to ? { description: delivery.to } : null}
                    onChange={(place) => pressedPrediction("to", place)}
                    type="to"
                    pressedPrediction={pressedPrediction}
                    GoogleMapsComponent={GoogleMaps}
                  />

                  <BusCompanySelector
                    value={bussCompany2}
                    onChange={(value) => {
                      dispatch(
                        changeActiveDeliveryValues({
                          fieldValue: "bussCompany2",
                          val: value,
                        })
                      );
                    }}
                  />

                  <Box sx={{ mb: 3 }}>
                    <Typography fontWeight="bold" mb={1}>
                      {t("delivery.arrivalTime", "Saapumisaika")}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        value={
                          delivery?.arrivalTime
                            ? moment(delivery.arrivalTime)
                            : moment().add(1, "hour")
                        }
                        onChange={(date) =>
                          handleOnChangeTime("arrivalTime", date?.toDate())
                        }
                        slotProps={{
                          textField: { fullWidth: true },
                        }}
                        format="DD.MM.YYYY HH:mm"
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              );
            } else {
              // Middle bus segment, no specific UI
              stepContent = (
                <Box>
                  <Typography variant="body1">
                    {t(
                      "delivery.busContinues",
                      "Bussikuljetus jatkuu vaihdon jälkeen"
                    )}
                  </Typography>
                </Box>
              );
            }
          }
          break;

        case "change":
          stepContent = (
            <Box>
              <AddressInput
                label={t("delivery.changeStation", "Vaihtoasema")}
                value={
                  delivery?.bussChangeAdress
                    ? { description: delivery.bussChangeAdress.name }
                    : null
                }
                onChange={(place) => pressedPrediction("bussChange", place)}
                type="bussChange"
                pressedPrediction={pressedPrediction}
                GoogleMapsComponent={GoogleMaps}
              />

              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography fontWeight="bold" mb={1}>
                      {t("delivery.arrivalAtStation", "Saapuu vaihtoasemalle")}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        value={
                          delivery?.changeTimeFrom
                            ? moment(delivery.changeTimeFrom)
                            : moment()
                        }
                        onChange={(date) =>
                          handleOnChangeTime("changeTimeFrom", date?.toDate())
                        }
                        slotProps={{
                          textField: { fullWidth: true },
                        }}
                        format="DD.MM.YYYY HH:mm"
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography fontWeight="bold" mb={1}>
                      {t(
                        "delivery.departureFromStation",
                        "Lähtee vaihtoasemalta"
                      )}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        value={
                          delivery?.changeTimeTo
                            ? moment(delivery.changeTimeTo)
                            : moment().add(30, "minutes")
                        }
                        onChange={(date) =>
                          handleOnChangeTime("changeTimeTo", date?.toDate())
                        }
                        slotProps={{
                          textField: { fullWidth: true },
                        }}
                        format="DD.MM.YYYY HH:mm"
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
          break;

        case "delivery":
          stepContent = (
            <Box>
              <AddressInput
                label={t("delivery.deliveryAddress", "Toimitus-osoite")}
                value={
                  delivery?.toSelfDeliverAdress
                    ? { description: delivery.toSelfDeliverAdress }
                    : null
                }
                onChange={(place) =>
                  pressedPrediction("toSelfDeliverAdress", place)
                }
                type="toSelfDeliverAdress"
                pressedPrediction={pressedPrediction}
                GoogleMapsComponent={GoogleMaps}
              />
            </Box>
          );
          break;

        default:
          stepContent = (
            <Box>{t("delivery.additionalInfo", "Lisätietoja")}</Box>
          );
      }

      steps.push({
        label: segment.name,
        content: stepContent,
      });
    });

    // Add final confirmation step
    steps.push({
      label: t("delivery.confirmDetails", "Vahvista tiedot"),
      content: (
        <Box>
          <Typography variant="h6" gutterBottom>
            {t("delivery.summary", "Yhteenveto kuljetuksesta")}
          </Typography>

          {renderPathVisualizer()}

          <Divider sx={{ my: 2 }} />

          {deliveryPath.map((segment, index) => {
            let summaryContent;

            switch (segment.type) {
              case "pickup":
                summaryContent = (
                  <Box>
                    <Typography variant="subtitle1">
                      {t("delivery.pickupAddressSummary", "Nouto-osoite:")}
                    </Typography>
                    <Typography variant="body1">
                      {delivery?.fromSelfDeliverAdress ||
                        t("delivery.notSpecified", "Ei määritelty")}
                    </Typography>
                  </Box>
                );
                break;

              case "bus":
                if (index === deliveryPath.findIndex((p) => p.type === "bus")) {
                  summaryContent = (
                    <Box>
                      <Typography variant="subtitle1">
                        {t("delivery.departureStopSummary", "Lähtöpysäkki:")}
                      </Typography>
                      <Typography variant="body1">
                        {delivery?.from ||
                          t("delivery.notSpecified", "Ei määritelty")}
                      </Typography>

                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        {t("delivery.busCompanySummary", "Bussiyhtiö")}
                      </Typography>
                      <Typography variant="body1">
                        {delivery?.bussCompany
                          ? busCompanies.find(
                              (c) => c.value === delivery.bussCompany
                            )?.label || delivery.bussCompany
                          : t("delivery.notSpecified", "Ei määritelty")}
                      </Typography>

                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        {t("delivery.departureTimeSummary", "Lähtöaika:")}
                      </Typography>
                      <Typography variant="body1">
                        {delivery?.departureTime
                          ? moment(delivery.departureTime).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          : t("delivery.notSpecified", "Ei määritelty")}
                      </Typography>
                    </Box>
                  );
                } else if (
                  index === deliveryPath.map((p) => p.type).lastIndexOf("bus")
                ) {
                  summaryContent = (
                    <Box>
                      <Typography variant="subtitle1">
                        {t("delivery.arrivalStopSummary", "Saapumispysäkki:")}
                      </Typography>
                      <Typography variant="body1">
                        {delivery?.to ||
                          t("delivery.notSpecified", "Ei määritelty")}
                      </Typography>

                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        {t("delivery.busCompanySummary", "Bussiyhtiö")}
                      </Typography>
                      <Typography variant="body1">
                        {delivery?.bussCompany2
                          ? busCompanies.find(
                              (c) => c.value === delivery.bussCompany2
                            )?.label || delivery.bussCompany2
                          : t("delivery.notSpecified", "Ei määritelty")}
                      </Typography>

                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        {t("delivery.arrivalTimeSummary", "Saapumisaika:")}
                      </Typography>
                      <Typography variant="body1">
                        {delivery?.arrivalTime
                          ? moment(delivery.arrivalTime).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          : t("delivery.notSpecified", "Ei määritelty")}
                      </Typography>
                    </Box>
                  );
                } else {
                  summaryContent = (
                    <Box>
                      <Typography variant="subtitle1">
                        {t(
                          "delivery.busContinuesSummary",
                          "Bussikuljetus vaihdon jälkeen"
                        )}
                      </Typography>
                    </Box>
                  );
                }
                break;

              case "change":
                summaryContent = (
                  <Box>
                    <Typography variant="subtitle1">
                      {t("delivery.changeStationSummary", "Vaihtoasema:")}
                    </Typography>
                    <Typography variant="body1">
                      {delivery?.bussChangeAdress?.name ||
                        t("delivery.notSpecified", "Ei määritelty")}
                    </Typography>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2">
                          {t(
                            "delivery.arrivalAtStationSummary",
                            "Saapumisaika:"
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {delivery?.changeTimeFrom
                            ? moment(delivery.changeTimeFrom).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : t("delivery.notSpecified", "Ei määritelty")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2">
                          {t(
                            "delivery.departureFromStationSummary",
                            "Lähtöaika:"
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {delivery?.changeTimeTo
                            ? moment(delivery.changeTimeTo).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : t("delivery.notSpecified", "Ei määritelty")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                );
                break;
              case "delivery":
                summaryContent = (
                  <Box>
                    <Typography variant="subtitle1">
                      {t("delivery.deliveryAddressSummary", "Toimitus-osoite:")}
                    </Typography>
                    <Typography variant="body1">
                      {delivery?.toSelfDeliverAdress ||
                        t("delivery.notSpecified", "Ei määritelty")}
                    </Typography>
                  </Box>
                );
                break;
              default:
                summaryContent = (
                  <Box>{t("delivery.additionalInfo", "Lisätietoja")}</Box>
                );
            }
            return (
              <Box key={`summary-${index}`} sx={{ mb: 3 }}>
                {renderPathIcon(segment.type)}
                <Typography variant="h6">{segment.name}</Typography>
                {summaryContent}
              </Box>
            );
          })}
        </Box>
      ),
    });
    return steps;
  };
  const steps = getSteps();

  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <div style={{ padding: "8px 0" }}>{step.content}</div>
              <div style={{ marginBottom: 16 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{ marginRight: 8 }}
                >
                  Takaisin
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    activeStep === steps.length - 1 ? onSubmit : handleNext
                  }
                  disabled={
                    (selectedTemplate === "custom" &&
                      deliveryPath.length === 0) ||
                    (activeStep === steps.length - 1 && !onSubmit)
                  }
                >
                  {activeStep === steps.length - 2
                    ? "Vahvista"
                    : activeStep === steps.length - 1
                    ? "Jatka tilaukseen"
                    : "Seuraava"}
                </Button>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
export default DeliveryPathSelector;
