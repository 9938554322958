import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  AppBar,
  Toolbar,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Container,
  Alert,
  Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaymentIcon from "@mui/icons-material/Payment";
import { useTranslation } from "react-i18next";
import DeliveryPathSelector from "./DeliveryPathSelector";
import PackageDetailsStep from "./PackageDetailsStep";
import PaymentStep from "./PaymentStep";
import { changeActiveDeliveryValues } from "../../redux/deliverySlice";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../../App";

const DeliveryDialog = ({ open, onClose, user, dispatch }) => {
  const { delivery } = user;
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [error, setError] = useState("");

  // Steps in the delivery process
  const steps = [
    {
      label: t("delivery.steps.route", "Choose Delivery Route"),
      description: t(
        "delivery.steps.routeDesc",
        "Select how you want your package delivered"
      ),
      icon: <LocalShippingIcon />,
    },
    {
      label: t("delivery.steps.details", "Package Details"),
      description: t(
        "delivery.steps.detailsDesc",
        "Tell us about your package"
      ),
      icon: <InventoryIcon />,
    },
    {
      label: t("delivery.steps.payment", "Payment"),
      description: t("delivery.steps.paymentDesc", "Complete your order"),
      icon: <PaymentIcon />,
    },
  ];

  const handleNext = () => {
    // Validate current step before proceeding
    if (activeStep === 0) {
      // Validate route selection
      const { from, to } = delivery;
      if (!from || !to) {
        setError(
          t(
            "delivery.routeValidationError",
            "Täytä vaaditut tiedot, rullaa ikkunaa alaspäin."
          )
        );
        return;
      }
    } else if (activeStep === 1) {
      // Validate sender and receiver information
      const { senderName, senderPhone, receiverName, receiverPhone } = delivery;
      if (!senderName || !senderPhone || !receiverName || !receiverPhone) {
        setError(
          t(
            "delivery.contactValidationError",
            "Please fill in all required fields (sender and receiver name and phone)"
          )
        );
        return;
      }
    } else if (activeStep === 2) {
      // Validate payment information
      if (delivery.paymentMethod === "bill") {
        const { companyName, businessId } = delivery;
        if (!companyName || !businessId) {
          setError(
            t(
              "delivery.companyValidationError",
              "Please fill in company name and business ID"
            )
          );
          return;
        }
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (index) => {
    // Only allow clicking on completed steps
    if (index <= activeStep) {
      setActiveStep(index);
      setDrawerOpen(false);
    }
  };

  const createPayment = async (delivery) => {
    const functions = getFunctions(app);
    const deliveryWithCorrectValues = {
      ...delivery,
      createdBy: "xkDLja7wSzPBcyqxzMZV26FFFPq2",
      uid: "xkDLja7wSzPBcyqxzMZV26FFFPq2",
    };
    const saveDelivery = httpsCallable(functions, "saveDeliveryAndNotifySlack");
    try {
      const result = await saveDelivery(deliveryWithCorrectValues);
    } catch (error) {
      console.error("Error saving delivery or notifying Slack:", error);
    }
  };

  const handleSubmit = () => {
    // Validate final payment information
    if (delivery.paymentMethod === "bill") {
      const { companyName, businessId } = delivery;
      if (!companyName || !businessId) {
        setError(
          t(
            "delivery.companyValidationError",
            "Please fill in company name and business ID"
          )
        );
        createPayment(delivery);
        return;
      }
    }

    // For credit card payments, redirect to payment gateway
    if (delivery.paymentMethod === "card") {
      // Simulate redirection to payment gateway
      alert(
        t("payment.redirectingToGateway", "Redirecting to payment gateway...")
      );
      createPayment(delivery);
      // In real implementation, we would process this through a payment provider
    } else {
      // For bill payments, just show confirmation
      alert(t("payment.invoiceSent", "Invoice will be sent to your email"));
    }

    // Close the dialog
    onClose();
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleErrorClose = () => {
    setError("");
  };

  const stepDrawer = (
    <SwipeableDrawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          <ListItem sx={{ justifyContent: "center", py: 2 }}>
            <Typography variant="h6">
              {t("delivery.steps.title", "Delivery Steps")}
            </Typography>
          </ListItem>
          <Divider />
          {steps.map((step, index) => (
            <ListItem
              button
              key={step.label}
              onClick={() => handleStepClick(index)}
              selected={activeStep === index}
              disabled={index > activeStep}
              sx={{ opacity: index > activeStep ? 0.5 : 1 }}
            >
              <ListItemIcon>{step.icon}</ListItemIcon>
              <ListItemText primary={step.label} />
            </ListItem>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );

  const renderMobileHeader = () => (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        {activeStep > 0 ? (
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBack}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography variant="h6" component="div" sx={{ ml: 2, flex: 1 }}>
          {steps[activeStep].label}
        </Typography>
        {activeStep < steps.length - 1 && (
          <Button color="primary" onClick={handleNext}>
            {t("common.next", "Next")}
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button color="primary" onClick={handleSubmit}>
            {t("delivery.submit", "Complete")}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );

  const renderDesktopHeader = () => (
    <>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="div">
          {t("delivery.title", "Create New Delivery")}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ px: 3, pt: 1 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Paper
          elevation={0}
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: theme.palette.background.default,
            mb: 2,
          }}
        ></Paper>
      </Box>
    </>
  );

  const isLastStep = activeStep === steps.length - 1;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, sm: 2 },
          height: isMobile ? "100%" : "auto",
          maxHeight: { sm: "90vh" },
          m: isMobile ? 0 : 2,
        },
      }}
    >
      {isMobile ? renderMobileHeader() : renderDesktopHeader()}
      {stepDrawer}

      <DialogContent
        dividers={!isMobile}
        sx={{
          p: isMobile ? 2 : 3,
          pb: isMobile ? 8 : 3, // Add padding at bottom for mobile to account for fixed buttons
          height: isMobile ? "calc(100% - 144px)" : "auto",
          overflowY: "auto",
        }}
      >
        <Container maxWidth="md" disableGutters>
          {activeStep === 0 && (
            <DeliveryPathSelector
              delivery={delivery}
              dispatch={dispatch}
              pressedPrediction={(type, data) => {
                if (!data) return;
                // Handle both Google Places API results and manual entries
                dispatch(
                  changeActiveDeliveryValues({
                    fieldValue: type,
                    val: data,
                  })
                );

                // If it's a manual entry and you need coordinates, you might need to
                // handle this case differently, for example by using a geocoding service
                if (data.isManualEntry) {
                  console.log(
                    "Manual address entered, might need geocoding for coordinates"
                  );
                }
              }}
              handleOnChangeTime={(type, date) => {
                dispatch(
                  changeActiveDeliveryValues({
                    fieldValue: type,
                    val: date,
                  })
                );
              }}
              onSubmit={handleNext}
              isMobile={isMobile}
            />
          )}

          {activeStep === 1 && (
            <PackageDetailsStep
              delivery={delivery}
              dispatch={dispatch}
              isMobile={isMobile}
              changeActiveDeliveryValues={changeActiveDeliveryValues}
            />
          )}

          {activeStep === 2 && (
            <PaymentStep
              delivery={delivery}
              dispatch={dispatch}
              isMobile={isMobile}
              changeActiveDeliveryValues={changeActiveDeliveryValues}
            />
          )}
        </Container>
      </DialogContent>

      {/* Fixed bottom navigation buttons for mobile */}
      {isMobile && (
        <AppBar
          position="fixed"
          color="default"
          sx={{ top: "auto", bottom: 0 }}
        >
          <Toolbar>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="text"
                onClick={toggleDrawer(true)}
                sx={{ textTransform: "none" }}
              >
                {t("common.steps", "Steps")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={isLastStep ? handleSubmit : handleNext}
                sx={{ minWidth: 120 }}
              >
                {isLastStep
                  ? t("delivery.submit", "Complete Order")
                  : t("common.next", "Next")}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}

      {/* Desktop navigation buttons */}
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            borderTop: 1,
            borderColor: "divider",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{ mr: 1 }}
          >
            {t("common.back", "Back")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={isLastStep ? handleSubmit : handleNext}
          >
            {isLastStep
              ? t("delivery.submit", "Complete Order")
              : t("common.next", "Next")}
          </Button>
        </Box>
      )}

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.delivery,
});

export default connect(mapStateToProps)(DeliveryDialog);
