import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Button
} from "@mui/material";
import {
  LocalShipping,
  LocationCity,
  AttachMoney,
  Flight,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import busImage from "../assets/buss.png";
import DeliveryDialog from "../components/delivery/DeliveryDialog";

const BusPage = () => {
  const { t } = useTranslation();
  const [openDeliveryDialog, setOpenDeliveryDialog] = useState(false);

  const handleOpenDeliveryDialog = () => {
    setOpenDeliveryDialog(true);
  };

  const handleCloseDeliveryDialog = () => {
    setOpenDeliveryDialog(false);
  };

  const SendPackageButton = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDeliveryDialog}
        sx={{
          textTransform: "none",
          padding: "12px 24px",
          fontSize: "16px",
          minWidth: "200px",
        }}
      >
        {t(`${props.text}`, "Send a Package Now")}
      </Button>
    );
  };

  return (
    <Container maxWidth="md" sx={{ my: 6 }}>
      {/* Hero Section */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <img
          src={busImage}
          alt="Bus Service"
          style={{ width: "100%", height: "auto", maxWidth: "600px" }}
        />
        <Typography
          variant="h3"
          gutterBottom
          sx={{ mt: 2, fontWeight: "bold" }}
        >
          {t("busService.title", "MyXline City-to-City Deliveries")}
        </Typography>
        <Typography variant="h6" color="textSecondary" sx={{ mb: 3 }}>
          {t(
            "busService.subtitle",
            "Affordable and Reliable Intercity Deliveries with MyXline"
          )}
        </Typography>

        {/* Button in hero section */}
        <Box sx={{ mt: 3, mb: 4 }}>
          <SendPackageButton
          text='busService.calculatePriceBus'
          
          />
        </Box>
      </Box>

      {/* Service Details Section */}
      <Paper
        elevation={3}
        sx={{ padding: 4, backgroundColor: "#f9f9f9", mb: 4 }}
      >
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <LocalShipping sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.howItWorksTitle", "How It Works")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.howItWorksDescription",
            "With MyXline's intercity delivery service, you can send packages from one city to another efficiently and at a low cost. Our service allows you to drop off your package at a designated location in your city, and we handle the rest, delivering it to your recipient in the destination city."
          )}
        </Typography>

        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <LocationCity sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.cityToCityTitle", "City-to-City Coverage")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.cityToCityDescription",
            "We connect major cities across the country, allowing you to send packages to your destination quickly and reliably. Drop-offs and pickups are handled at central points for convenience."
          )}
        </Typography>

        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <AttachMoney sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.affordableTitle", "Affordable Pricing")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.affordableDescription",
            "Our city-to-city service is designed to be budget-friendly. Starting at just 30 € + VAT, you can enjoy a cost-effective way to send your packages and items across cities."
          )}
        </Typography>

        {/* New Baltic Countries Section */}
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <Flight sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.internationalTitle", "International Deliveries")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.internationalDescription",
            "Need to send packages to the Baltic countries? We offer delivery services to Estonia, Latvia, and Lithuania upon request. Contact our customer service for more information about international shipping options and pricing."
          )}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          href="mailto:info@myxline.fi"
          sx={{
            textTransform: "none",
            mt: 1,
          }}
        >
          {t("busService.contactUs", "Contact Us for International Deliveries")}
        </Button>
      </Paper>

      {/* Video Section */}
      <Box sx={{ mb: 4, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom>
          {t("busService.watchVideo", "Watch How It Works")}
        </Typography>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 aspect ratio */,
            height: 0,
            overflow: "hidden",
            "& iframe": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            },
          }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/sepqxsFTiIY"
            title="MyXline City-to-City Delivery Service"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          />
        </Box>
      </Box>

      {/* Call-to-Action Section */}
      <Box textAlign="center" sx={{ mt: 4 }}>
         <Button
        variant="contained"
        color="primary"
        href="https://app.myxline.fi/asiakassivu"
        sx={{
          textTransform: "none",
          padding: "12px 24px",
          fontSize: "16px",
          minWidth: "200px",
        }}
      >
        {t("busService.sendNow")}
      </Button>
      </Box>

      {/* Delivery Dialog */}
      <DeliveryDialog 
        open={openDeliveryDialog} 
        onClose={handleCloseDeliveryDialog} 
      />
    </Container>
  );
};

export default BusPage;