// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './styles/App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import InfoPage from './pages/InfoPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import DeliveryConfirmationScreen from './components/DeliveryConfirmationScreen';
import DeliveryDetailsScreen from './components/DeliveryDetailsScreen';
import PricingPage from './components/PricingPage';
import DownloadPage from './pages/Download';
import GeneralTerms from './pages/GeneralTerms';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'; // Import authentication
import { getAnalytics } from 'firebase/analytics';
import Guides from './pages/Guides';
import DeliveryStatus from './pages/DeliveryStatus'; // Import the new component
import BusPage from './pages/BusPage';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider
import { getStorage } from 'firebase/storage'; // Import Firebase Storage
import { getFirestore } from 'firebase/firestore'; // Import Firestore
import NewsLetter from './pages/NewsLetter';
import Flowers from './pages/Flowers';
import ApiPage from './pages/ApiPage';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCWg8bk28knXsOLxPpKVMRaGoa37tHxpsY',
  authDomain: 'myxline-fin.firebaseapp.com',
  projectId: 'myxline-fin',
  storageBucket: 'myxline-fin.appspot.com',
  messagingSenderId: '626397172965',
  appId: '1:626397172965:web:bd27ee9c6775f86c33b00c',
  measurementId: 'G-MFRZ9ZBYG9',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Initialize Firebase Auth
export const db = getFirestore(app); // Initialize Firestore
export const storage = getStorage(app); // Initialize Firebase Storage
const analytics = getAnalytics(app);

// Custom MUI theme with Space Mono as the default font
const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
  },
});

function ExternalRedirect() {
  React.useEffect(() => {
    window.location.href = 'https://app.myxline.fi/asiakassivu';
  }, []);
  return null;
}

const NotFound = () => <h2>404 Page Not Found</h2>;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider> {/* Wrap AuthProvider around the entire app */}
      <Router>
        <Navbar />
        {/* Define Routes */}
        <Routes>

        <Route path="/asiakassivu" element={<ExternalRedirect />} />


          <Route path="/" element={<Home />} />
          {/* For marketing */}
          {/* Google */}
          <Route path="/laheta" element={<Home />} />
          {/* Faceboook */}
          <Route path="/tilaa" element={<Home />} />
           {/* Email */}
           <Route path="/joulu" element={<Home />} />
           {/* SMS */}
           <Route path="/eurolla/:id" element={<Home />} />
          
          {/* Bussikamppis */}
          <Route path="/bussi" element={<BusPage />} />
          <Route path="/bussi/:id" element={<BusPage />} />
          
          {/* Integraatiot */}
          <Route path="/integrations" element={<ApiPage />} />
          <Route path="/integrations/:id" element={<ApiPage />} />


          {/* Delivery flow */}
          <Route path="/d" element={<DeliveryDetailsScreen />} />
          <Route path="/d/:id" element={<DeliveryDetailsScreen />} />

          <Route path="/dc" element={<DeliveryConfirmationScreen />} />
          <Route path="/dc/:id" element={<DeliveryConfirmationScreen />} />


          <Route path="/yhteystiedot" element={<Contact />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/help" element={<InfoPage />} />
          <Route path="/hinnasto" element={<PricingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/cancel-newsletter/:id" element={<NewsLetter />} />


          <Route path="/log-in" element={<DownloadPage />} />
          <Route path="/create-user" element={<DownloadPage />} />
          <Route path="/terms-and-conditions" element={<GeneralTerms />} />

          {/* Flower */}
          <Route path="/kukat" element={<Flowers />} />
          <Route path="/kukat/:id" element={<Flowers />} />

          {/* Delivery flow */}
          <Route path="/kuljetus/:id" element={<DeliveryStatus />} />
          <Route path="/vastaanota/:id" element={<DeliveryStatus />} />
          {/* Guides */}
          <Route path="/guides/payments" element={<Guides />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
      </AuthProvider>
    </ThemeProvider>
    
  );
}

export default App;
