import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Paper,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import moment from "moment";
import { 
  getCoordinatesByPlaceId, 
  getDirectionsByPlaceIds 
} from "../../utils/LocationFunctions";
import { setDistanceInfo } from "../../redux/deliverySlice";

const PackageDetailsStep = ({ delivery, dispatch, isMobile, changeActiveDeliveryValues }) => {
  const { t } = useTranslation();
  const [isCalculating, setIsCalculating] = useState(false);

  // Calculate distances when relevant place IDs change
  useEffect(() => {
    const calculateDistances = async () => {
      if (!delivery) return;
      
      setIsCalculating(true);
      try {
        // Calculate pickup distance if pickup service is selected
        if (delivery.fromSelfDeliverPlaceId && delivery.fromPlaceId) {
          try {
            const directions = await getDirectionsByPlaceIds(
              delivery.fromSelfDeliverPlaceId,
              delivery.fromPlaceId
            );
            
            if (directions && directions.distance) {
              // Store pickup distance in a dedicated field
              dispatch(
                changeActiveDeliveryValues({
                  fieldValue: "pickupDistance",
                  val: directions.distance.value, // in meters
                })
              );
            }
          } catch (error) {
            console.error("Error calculating pickup distance:", error);
          }
        } else {
          // Clear pickup distance if no pickup service
          dispatch(
            changeActiveDeliveryValues({
              fieldValue: "pickupDistance",
              val: 0,
            })
          );
        }

        // Calculate delivery distance if delivery service is selected
        if (delivery.toSelfDeliverPlaceId && delivery.toPlaceId) {
          try {
            const directions = await getDirectionsByPlaceIds(
              delivery.toPlaceId,
              delivery.toSelfDeliverPlaceId
            );
            
            if (directions && directions.distance) {
              // Store delivery distance in a dedicated field
              dispatch(
                changeActiveDeliveryValues({
                  fieldValue: "deliveryDistance",
                  val: directions.distance.value, // in meters
                })
              );
            }
          } catch (error) {
            console.error("Error calculating delivery distance:", error);
          }
        } else {
          // Clear delivery distance if no delivery service
          dispatch(
            changeActiveDeliveryValues({
              fieldValue: "deliveryDistance",
              val: 0,
            })
          );
        }

        // Calculate main transport distance (bus route)
        if (delivery.fromPlaceId && delivery.toPlaceId) {
          try {
            const directions = await getDirectionsByPlaceIds(
              delivery.fromPlaceId,
              delivery.toPlaceId
            );
            
            if (directions) {
              // Dispatch the distance and duration to Redux
              dispatch(
                setDistanceInfo({
                  distance: directions.distance.value, // meters
                  duration: directions.duration.value, // seconds
                })
              );
            }
          } catch (error) {
            console.error("Error calculating main transport distance:", error);
          }
        }
        
        // Calculate price based on updated distances
        calculatePrice();
        
      } catch (error) {
        console.error("Error in distance calculations:", error);
      } finally {
        setIsCalculating(false);
      }
    };

    calculateDistances();
  }, [
    delivery?.fromPlaceId, 
    delivery?.toPlaceId, 
    delivery?.fromSelfDeliverPlaceId, 
    delivery?.toSelfDeliverPlaceId,
    dispatch
  ]);

  // Calculate the price based on the delivery configuration and distances
  const calculatePrice = () => {
    let price = 0;
    
    // Base price for bus transport
    if (delivery?.from && delivery?.to) {
      price += 17.90; // Base price for single bus transport
      
      // Additional fee for bus change if present
      if (delivery?.bussChangeAdress?.name) {
        price += 24.9; // Fee for bus change service
      }
    }
    
    // Calculate pickup fee if applicable
    if (delivery?.fromSelfDeliverAdress) {
      // Base pickup fee for up to 10 km
      price += 15.00;
      
      // Additional fee for distances over 10 km
      const pickupDistanceKm = delivery.pickupDistance ? delivery.pickupDistance / 1000 : 0; // Convert to kilometers
      if (pickupDistanceKm > 10) {
        const extraKm = Math.ceil(pickupDistanceKm - 10);
        price += extraKm * 0.95; // 0.95€ per extra km
      }
    }
    
    // Calculate delivery fee if applicable
    if (delivery?.toSelfDeliverAdress) {
      // Base delivery fee for up to 10 km
      price += 15.00;
      
      // Additional fee for distances over 10 km
      const deliveryDistanceKm = delivery.deliveryDistance ? delivery.deliveryDistance / 1000 : 0; // Convert to kilometers
      if (deliveryDistanceKm > 10) {
        const extraKm = Math.ceil(deliveryDistanceKm - 10);
        price += extraKm * 0.95; // 0.95€ per extra km
      }
    }
    
    // Update price in Redux
    dispatch(
      changeActiveDeliveryValues({
        fieldValue: "price",
        val: parseFloat(price.toFixed(2)),
      })
    );
  };

  const handleInputChange = (field, value) => {
    dispatch(
      changeActiveDeliveryValues({
        fieldValue: field,
        val: value,
      })
    );
  };

  // Determine delivery type and path for summary
  const getDeliverySummary = () => {
    const hasPickup = !!delivery?.fromSelfDeliverAdress;
    const hasDelivery = !!delivery?.toSelfDeliverAdress;
    const hasBusChange = !!delivery?.bussChangeAdress?.name;
    
    // Construct a summary based on the delivery path
    const segments = [];
    
    if (hasPickup) {
      segments.push({
        icon: <LocalShippingIcon sx={{ transform: 'scaleX(-1)' }} />,
        label: t("deliverySegments.pickup", "MyXline pickup"),
        value: delivery?.fromSelfDeliverAdress || "-",
        subvalue: delivery.pickupDistance > 0 ? 
          `${t("delivery.distance", "Distance")}: ${(delivery.pickupDistance / 1000).toFixed(1)} km` : ""
      });
    }
    
    segments.push({
      icon: <DirectionsBusIcon />,
      label: t("deliverySegments.bus", "Bus transport"),
      value: `${delivery?.from || "-"} → ${delivery?.to || "-"}`,
      subvalue: `${delivery?.bussCompany ? `${t("delivery.busCompanySummary", "Bus company")}: ${delivery.bussCompany}` : ""}`
    });
    
    if (hasBusChange) {
      segments.push({
        icon: <SwapHorizIcon />,
        label: t("deliverySegments.change", "Connection"),
        value: delivery?.bussChangeAdress?.name || "-",
        subvalue: delivery?.changeTimeFrom && delivery?.changeTimeTo ? 
          `${moment(delivery.changeTimeFrom).format("HH:mm")} → ${moment(delivery.changeTimeTo).format("HH:mm")}` : ""
      });
      
      segments.push({
        icon: <DirectionsBusIcon />,
        label: t("deliverySegments.bus2", "Bus transport 2"),
        value: `${delivery?.bussChangeAdress?.name || "-"} → ${delivery?.to || "-"}`,
        subvalue: `${delivery?.bussCompany2 ? `${t("delivery.busCompanySummary", "Bus company")}: ${delivery.bussCompany2}` : ""}`
      });
    }
    
    if (hasDelivery) {
      segments.push({
        icon: <LocalShippingIcon />,
        label: t("deliverySegments.delivery", "MyXline delivery"),
        value: delivery?.toSelfDeliverAdress || "-",
        subvalue: delivery.deliveryDistance > 0 ? 
          `${t("delivery.distance", "Distance")}: ${(delivery.deliveryDistance / 1000).toFixed(1)} km` : ""
      });
    }
    
    return segments;
  };

  const deliverySummary = getDeliverySummary();

  return (
    <Box>
      {/* Delivery Summary Section */}
      <Paper elevation={1} sx={{ p: 2, mb: 3, position: 'relative' }}>
        <Typography variant="h6" gutterBottom>
          {t("delivery.routeSummary", "Delivery Route Summary")}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        
        {isCalculating && (
          <Box 
            sx={{ 
              position: 'absolute', 
              top: 0, 
              left: 0, 
              right: 0, 
              bottom: 0, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1
            }}
          >
            <CircularProgress size={40} />
          </Box>
        )}
        
        {deliverySummary.map((segment, index) => (
          <Box 
            key={`segment-${index}`}
            sx={{ 
              display: "flex", 
              alignItems: "flex-start", 
              mb: 2,
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <Box 
              sx={{ 
                display: "flex", 
                alignItems: "center",
                minWidth: isMobile ? "100%" : "180px",
                mb: isMobile ? 1 : 0
              }}
            >
              {segment.icon}
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {segment.label}
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body1">
                {segment.value}
              </Typography>
              {segment.subvalue && (
                <Typography variant="body2" color="text.secondary">
                  {segment.subvalue}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
        
        {delivery?.departureTime && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <AccessTimeIcon sx={{ color: "text.secondary", mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              {t("delivery.timeRange", "Time")}:{" "}
              {moment(delivery.departureTime).format("DD.MM.YYYY HH:mm")} →{" "}
              {delivery?.arrivalTime ? moment(delivery.arrivalTime).format("DD.MM.YYYY HH:mm") : "-"}
            </Typography>
          </Box>
        )}
         {/* Price Display */}
         {delivery?.price > 0 && (
          <Box 
            sx={{ 
              mt: 2, 
              p: 2, 
              backgroundColor: 'primary.light', 
              borderRadius: 1,
              color: 'primary.contrastText'
            }}
          >
            <Typography variant="subtitle1">
              {t("payment.estimatedPrice", "Estimated Price")}: {delivery.price.toFixed(2)} € ({t("payment.excludingVAT", "excl. VAT")})
            </Typography>
          </Box>
        )}
      </Paper>

      {/* Contact Information Section */}
      <Grid container spacing={3}>
        {/* Sender Information */}
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6" gutterBottom>
              {t("delivery.sender", "Sender Information")}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <TextField
              fullWidth
              label={t("delivery.senderName", "Sender's Name")}
              variant="outlined"
              margin="normal"
              value={delivery?.senderName || ""}
              onChange={(e) => handleInputChange("senderName", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
            
            <TextField
              fullWidth
              label={t("delivery.senderPhone", "Sender's Phone")}
              variant="outlined"
              margin="normal"
              value={delivery?.senderPhone || ""}
              onChange={(e) => handleInputChange("senderPhone", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
            
            <TextField
              fullWidth
              label={t("delivery.senderEmail", "Sender's Email")}
              variant="outlined"
              margin="normal"
              value={delivery?.senderEmail || ""}
              onChange={(e) => handleInputChange("senderEmail", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
        </Grid>
        
        {/* Receiver Information */}
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6" gutterBottom>
              {t("delivery.receiver", "Receiver Information")}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <TextField
              fullWidth
              label={t("delivery.receiverName", "Receiver's Name")}
              variant="outlined"
              margin="normal"
              value={delivery?.receiverName || ""}
              onChange={(e) => handleInputChange("receiverName", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
            
            <TextField
              fullWidth
              label={t("delivery.receiverPhone", "Receiver's Phone")}
              variant="outlined"
              margin="normal"
              value={delivery?.receiverPhone || ""}
              onChange={(e) => handleInputChange("receiverPhone", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
            
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={delivery?.requirePin || false}
                    onChange={(e) => handleInputChange("requirePin", e.target.checked)}
                    name="requirePin"
                  />
                }
                label={t("delivery.requirePin", "Require PIN code for delivery")}
              />
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={delivery?.canBeLeftAtTheDoor || false}
                    onChange={(e) => handleInputChange("canBeLeftAtTheDoor", e.target.checked)}
                    name="canBeLeftAtTheDoor"
                  />
                }
                label={t("delivery.canBeLeftAtDoor", "Can be left at the door")}
              />
            </Box>
          </Paper>
        </Grid>
        
        {/* Package Information (Optional) */}
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t("delivery.packageNotes", "Package Notes")}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <TextField
              fullWidth
              label={t("delivery.pickupNote", "Pickup Notes (e.g., access instructions)")}
              variant="outlined"
              margin="normal"
              multiline
              rows={2}
              value={delivery?.extraInfo || ""}
              onChange={(e) => handleInputChange("extraInfo", e.target.value)}
            />
            
            <TextField
              fullWidth
              label={t("delivery.deliveryNote", "Delivery Notes (e.g., where to leave package)")}
              variant="outlined"
              margin="normal"
              multiline
              rows={2}
              value={delivery?.toExtraInfo || ""}
              onChange={(e) => handleInputChange("toExtraInfo", e.target.value)}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageDetailsStep;