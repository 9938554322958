import React, { useEffect, useState } from "react";
import { 
  Typography, 
  Box, 
  Container, 
  IconButton, 
  Tooltip, 
  Dialog,
  DialogContent,
  Zoom,
  useTheme,
  useMediaQuery
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useLocation } from "react-router-dom";
import exampleImage from "../assets/laskutus.png";

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  alert("Kopioitu: " + text);
};

const InfoRow = ({ label, text }) => (
  <Box display="flex" alignItems="center" mb={1}>
    <Typography variant="body1" sx={{ fontWeight: 500 }}>
      {label}: {text}
    </Typography>
    <Tooltip title="Kopioi">
      <IconButton
        onClick={() => copyToClipboard(text)}
        size="small"
        sx={{ ml: 1 }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </Box>
);

const Guides = () => {
  const location = useLocation();
  const [openPreview, setOpenPreview] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  const handleImageClick = () => {
    setOpenPreview(true);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Ohjeet
      </Typography>

      <Box mb={4} id="payments">
        <Typography variant="h5" component="h2" gutterBottom>
          Palkkion maksaminen
        </Typography>
        <Box
            position="relative"
            sx={{
              cursor: 'pointer',
              '&:hover .zoom-icon': {
                opacity: 1,
              },
            }}
            onClick={handleImageClick}
          >
            <Box
              component="img"
              src={exampleImage}
              alt="Laskutusreferenssin esimerkki"
              sx={{
                maxWidth: { xs: "100%", md: "50%" },
                height: "auto",
                borderRadius: { xs: 4, md: 2 },
                boxShadow: 3,
                mt: { xs: 2, md: 0 },
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            />
            <Box
              className="zoom-icon"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                p: 1,
                opacity: 0,
                transition: 'opacity 0.2s',
              }}
            >
              <ZoomOutMapIcon sx={{ color: 'white' }} />
            </Box>
          </Box>
        {/* Kevytyrittäjät Section */}
        <Box mb={2}>
          <Typography variant="h6" component="h3" gutterBottom>
            Automaattinen maksaminen
          </Typography>
          <Box ml={2} mb={1}>
            <Typography variant="body1">
              - Palkkiot lähetetään maksuun joka viikko.
            </Typography>
            <Typography variant="body1">
              - Vain Omapajan asiakkaille automaattinen palkkionmaksu
            </Typography>
          </Box>
        </Box>

        {/* Laskuttavat Kevytyrittäjät Section */}
        <Box
          mb={2}
          id="manualpayment"
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "flex-start" }}
        >
          <Box flex={1} mr={{ md: 2 }}>
            <Typography variant="h6" component="h3" gutterBottom>
              Laskuttavat kevytyrittäjät
            </Typography>
            <Box ml={2} mb={1}>
              <Typography variant="body1">
                - Lähetä lasku MyXlinen sovelluksesta saamallasi
                laskutusreferenssillä
              </Typography>
              <Typography variant="body1">
                - Tuettuja kevytyrittäjäpalveluita ovat Ukko, Truster,
                Eezy ja PalkkaOnline
              </Typography>
              <Typography variant="body1">
                - Katso allaolevat laskutustiedot
              </Typography>
            </Box>
            {/* Yrittäjät Section */}
            <Box mb={2}>
              <Typography variant="h6" component="h3" gutterBottom>
                Yrittäjät
              </Typography>
              <Box ml={2} mb={1}>
                <Typography variant="body1">
                  - Lähetä lasku MyXlinen sovelluksesta saamallasi
                  laskutusreferenssillä
                </Typography>
                <Typography variant="body1">
                  - Katso allaolevat laskutustiedot
                </Typography>
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>

      {/* Image Preview Dialog */}

      <Dialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="lg"
        fullScreen={fullScreen}
        TransitionComponent={Zoom}
      >
        <DialogContent sx={{ p: 0, position: 'relative' }}>
          <IconButton
            onClick={() => setOpenPreview(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="img"
            src={exampleImage}
            alt="Laskutusreferenssin esimerkki"
            sx={{
              width: '100%',
              height: 'auto',
              display: 'block',
            }}
          />
        </DialogContent>
      </Dialog>


      {/* Laskutustiedot Section with Copy Buttons */}
      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Laskutustiedot
        </Typography>
        <InfoRow label="VERKKOLASKUOSOITE" text="003722856416" />
        <InfoRow
          label="VÄLITTÄJÄTUNNUS / VERKKOLASKUOPERAATTORI"
          text="MAVENTA"
        />
        <InfoRow label="Postiosoite" text="Narinkka 1, 00100 Helsinki" />
        <InfoRow label="Sähköpostilaskut" text="maksut(ät)myxline.fi" />
        <InfoRow label="Y-tunnus" text="2285641-6" />

        <Typography variant="h6" component="h3" gutterBottom mt={2}>
          <strong>Huom!</strong>
        </Typography>
        <Box ml={2}>
          <Typography variant="body1">Laskun maksuaika: 21 päivää</Typography>
          <Typography variant="body1">
            Lisää laskuun sovelluksesta saamasi laskutusreferenssi
            historia-sivulta.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Guides;